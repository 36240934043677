import { marked } from 'marked';
import type { FC } from 'react';
import { useEffect } from 'react';

import type { IBankDepositCard } from '@/types';
import type { IBlogArticleAttributes } from '@/types/blogInterfaces';
import { analyticServices } from '@/utils';

import { ArticleHero, OtherDeposits, RelatedArticles } from './parts';

export interface IArticleProps {
  article: IBlogArticleAttributes;
  readTime: number;
  deposits: IBankDepositCard[];
}

export const Article: FC<IArticleProps> = ({ article, readTime, deposits }) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      analyticServices.prepareHrefEventToSend(event);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      <ArticleHero
        title={article.Title}
        description={article.Description}
        readTime={readTime}
        publishedAt={article.publishedAt}
      />
      <div
        className="markdown-article mx-5 mb-5 flex flex-col gap-4 pt-8 md:mx-[34px] lg:mx-auto lg:flex lg:w-full lg:max-w-[849px] lg:pt-6"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: marked(article.Body) }}
      />
      {/* <TopDeposit /> */}
      <RelatedArticles
        className="mb-4"
        publishedAt={article.publishedAt}
        readTime={readTime}
        country={article.Country}
        slug={article.slug}
      />
      <OtherDeposits
        title="Top deposits available online"
        deposits={deposits}
      />
    </>
  );
};
